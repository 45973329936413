<template>
  <lf-content-loader :is-loading="isLoading" data-cy="lf-tab">
    <div
      class="transition-all border-b-4 cursor-pointer text-center pb-1-5 px-4 items-center text-headline capitalize tab"
      :class="[active ? 'border-primary' : 'border-transparent', computedStyle]"
    >
      <slot name="icon"></slot>
      <slot></slot>
    </div>
  </lf-content-loader>
</template>
<script setup lang="ts">
import { computed } from "vue";
import LfContentLoader from "./LfContentLoader.vue";

const props = defineProps({
  active: {
    type: Boolean,
    default: false
  },
  componentStyle: {
    type: String
  },
  isLoading: {
    type: Boolean
  }
});

const computedStyle = computed(() => {
  switch (props.componentStyle) {
    case "active-bolded":
      return props.active ? "font-bold" : "font-light";
    case "sidetab-style":
      return "sidetab";
    default:
      return "";
  }
});
</script>
<style scoped>
.underline {
  background: #3769d6;
}
.sidetab {
  @apply flex flex-row justify-between pt-3;
}
</style>
